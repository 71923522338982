.carousel-container {
  width: 50%;
  display: inline-flex;
  overflow-x: hidden;
  overflow-y: hidden;
  background: black;
  margin: 'auto';
}

.hide {
  display: none;
}

.slider-slide > img {
  height: 380px;
  object-fit: contain;
}

.image-gallery-component {
  width: 100%;
  margin: 0;
}

.carousel-portrait {
  width: 300px;
  margin: 0 auto;
}

.carousel-landscape {
  width: 100%;
  margin: auto 0;
}

#image-bubble-spin {
  margin: 0 auto;
}

@media only screen and (max-width: 750px) {
  .carousel-container {
    width: 100%;
  }
}
