.content {
  display: flex;
  height: 100vh;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  gap: 1rem;
  p {
    align-self: center;
    margin-top: 30px;
  }
}

.mobile-sign-out-btn {
  background-color: #00ADEF !important;
  width: 80%;
  padding: 10px 20px !important;
  align-self: center;
  margin-top: 40px !important;
  margin-bottom: 20px !important
}
