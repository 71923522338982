/**
Overall + Variables
**/

$path-blue: #1dafec;
$path-green: #2db151;
$path-purple: #9d00ff;
$border-line: 1px solid lightgrey;
$box-shadow-list: 0 10px 10px #888888;
$width: 100%;
$navWidth: 70%;
$height: 100%;
$detail-height: 420px;
$bottomDetail-height: 110vh;
$unitName-width: 45%;
$rentalFacts-width: 100%;
$unitDetails-width: 90%;
$unitDetails-padding: 26px 26px 26px 26px;
$unitDetails-header: 20px 0px 0px 0px;
$border-top-detail: 1px solid #d9d9d9;
$unitMap-width: 100%;
$halfUnitMap-width: 50%;
$imageGridList-width: 50%;
$detail-display: flex;
$background-contact-box: #ffffff;
// $box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
$box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.2), 0 6px 32px 0 rgba(0, 0, 0, 0.19);
$border-radius: 0 1px 1px rgba(0, 0, 0, 0.05);
$input-border-radius: 10px;
$text-align: left;

.col-container-navigation {
  display: flex;
  width: $navWidth;
  height: $height;
  border: none;
  margin-top: 0px;

  .col-header {
    text-align: center;
    vertical-align: middle;
    width: $width/5;
    overflow: hidden;
    border-right: $border-line;
  }
  .col-header .btn-filter,
  .col-header .reset {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .col-header:hover {
    background-color: rgb(160, 185, 161);
  }
}

/**
Terms and conditions
**/

#terms-here-keyword {
  color: blue;
  cursor: pointer;
  border-bottom: 1px solid;
}

/**
Top Logos
**/

.logos {
  display: flex;

  .topLogo {
    width: 110px;
    height: 110px;
    padding: 5px 0px 5px 0px;
    display: flex;
    margin: 0px 0px;
    margin-right: 140px;
  }

  .topLogo:hover {
    cursor: pointer;
  }
}

/**
Top Details - detail page
**/

.topDetails {
  height: $detail-height;
  display: $detail-display;
  border: $border-top-detail;

  .unitMap {
    width: $unitMap-width;
    display: none;
  }

  .half-unitMap {
    width: $halfUnitMap-width;
  }
}

.unit-detail-section {
  padding: 1rem 0;
  display: flex;
  flex-flow: column nowrap;
  .unit-detail-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 10px;
  }
}

/**
Bottom Details - detail page
**/
$address-margin: 0px 0px 0px 0px;
$address-padding: 3px 3px 3px 3px;
$address-font-size: 17px;

.bottomDetails {
  height: $bottomDetail-height;
  display: $detail-display;
  background: #ffffff;
  .unitDetails {
    width: $unitDetails-width;
    text-align: -webkit-left;
    padding: $unitDetails-padding;

    .container {
      padding-left: 0px;
    }

    .header {
      line-height: 0.7;
      margin: $unitDetails-header;
      padding-left: 13px;

      .Line1 {
        margin: $address-margin;
      }

      .Line2 {
        display: $detail-display;
        font-size: $address-font-size;
        font-weight: 100;
      }

      .Line2 p {
        padding: $address-padding;
      }

      .Program {
        display: $detail-display;
        font-size: $address-font-size;
        font-weight: 100;
        margin: 10px 0px;
        padding-left: 3px;
        line-height: 1.5;
      }
    }

    .rooms {
      ul {
        margin: $address-margin;
        list-style-type: none;
        padding-left: 16px;

        li {
          display: inline;
          padding: 6px 12px 6px 0px;
          font-size: 18px;
        }
      }
    }

    .descriptions {
      padding: 18px 0px 0px 15px;
      font-weight: 100;
      line-height: 1.8;
    }

    .facts {
      padding-left: 12px;
      display: $detail-display;

      p {
        font-size: 21px;
        padding-top: 0px;
        padding-left: 7px;
      }
    }

    .rentalFacts {
      padding-top: 20px;
      width: $rentalFacts-width;

      ul {
        list-style-type: none;
        padding-left: 0px;
        li {
          margin: 20px 0;
        }
      }

      .features {
        width: 100%;
        height: 100%;
        padding: 10px 10px 10px 16px;

        h2 {
          font-size: 1em;
          text-align: -webkit-left;
        }

        .col-container-features {
          display: table;
          width: $width + 1%;
          height: $height;

          .col-table-cell-features {
            display: table-cell;
            width: $width / 3;
            height: $height;

            .detailsHeader {
              display: flex;
              p {
                width: $width;
                margin: $address-margin;
                padding-top: 4px;
                padding-left: 4px;
              }
            }

            .listOfFeatures {
              text-align: -webkit-left;
            }
          }
        }
      }
    }
  }
}

/**
Contact Info Box - Detail page
**/

$border-bottom-detail: $border-top-detail;

.backButton {
  background-color: #ffffff;
  text-align: left;
  box-shadow: $box-shadow;
}

.bottomDetails {
  .contactInfoContainer {
    width: $width / 2.38;

    .contact-info-style {
      margin-right: 15px;
      margin-top: 12px;
      text-align: $text-align;
      height: $height;
      background-color: $background-contact-box;
      border: $border-top-detail;
      border-radius: $border-radius;
    }

    .rent {
      text-align: left;
      padding: 14px 0px 1px 25px;
      background-color: #f2f2f2;
      border-bottom: $border-bottom-detail;

      h3 {
        font-size: 30px;
        margin-bottom: 8px;
        margin-top: 12px;
      }

      p {
        display: inline;
      }
    }

    .message {
      height: 200px;
      padding: 4vh;

      .input-border {
        border: $border-line;
        border-radius: $input-border-radius;
        font-family: 'Open Sans', sans-serif;
        color: black;
        padding-left: 1vh;
      }

      ::-webkit-input-placeholder {
        font-family: 'Open Sans', sans-serif;
        color: gray;
        padding-left: 1vh;
      }

      ::-moz-placeholder {
        font-family: 'Open Sans', sans-serif;
        color: gray;
        padding-left: 1vh;
      }

      .user-info {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 50%;
        // padding: 2vh;
        .input.container {
          width: 50%;
          height: 50%;
          // border: 1px black solid;
          flex: 1 1 calc(50% - 10px);
          input {
            height: 60%;
            width: 80%;
          }
        }
      }

      .comments {
        width: 100%;
        height: 30%;
        padding: 0vh;
        .input.container {
          width: 100%;
          height: 100%;
          display: block;
          textarea {
            width: 90%;
            height: 100%;
            resize: none;
          }
        }
      }

      .button {
        width: 100%;
        height: 20%;
        text-align: center;
        margin: 2vh auto;
      }
    }

    .detail {
      padding: 0px 0px 1px 25px;
    }

    .phoneCall {
      padding: 10px 0px 15px 0px;
      border-top: $border-bottom-detail;
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      .row-one {
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        button {
          svg {
            margin-right: 10px;
          }
        }
      }
      .row-two {
        width: 100%;
        justify-content: center;
        align-items: center;
        button {
          min-width: 50%;
          svg {
            margin-right: 10px;
          }
        }
      }
    }

    .contactButton {
      a {
        text-decoration-line: none;
        color: black;
        padding-right: 5px;
      }
    }
  }
}

/**
List Component - Dashboard Page
**/

.col-content-listing {
  .Tablecell-rentname {
    cursor: 'pointer';
    display: 'flex';
    .list-bookmark-button {
      float: right;
      margin-left: auto;
    }
  }
  .MuiTableCell-root:hover {
    color: $path-purple;
  }
  .contactButton {
    a {
      text-decoration-line: none;
      color: black;
      padding-right: 5px;
    }
  }
  .loadingSpin {
    padding: 40px 190px 0px 160px;
  }
  .List {
    display: flex;
    margin-bottom: 15px;
    .sortInList {
      width: $width/2;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .favoriteButton {
      width: $width/2;
      padding-top: 15px;
    }
  }
}

/**
/the walkscore api container (up to 3 boxes)
**/

.unitWalkScore {
  padding-left: 16px;
  margin-top: 5vh;

  h1 {
    font-size: 1.5em;
    display: inline-block;
    margin-right: 2vh;
  }

  .walkscore-framebox {
    margin: 8vh 0vh;
    h1 {
      display: inline-block;
      margin-right: 2vh;
    }
  }

  .walkscore-api-container {
    display: flex;
    margin-top: 55px;

    .walkscore_logo {
      text-align: center;
      font-size: 20px;
      margin: 5px 0px;

      a {
        text-decoration: none;
        color: black;
        font-weight: bold;
      }
    }

    .score {
      margin-right: 30px;
      padding: 10px 10px;
      border: $border-line;
      // height: 130px;
      height: 100%;
      width: 25%;
      border-radius: 10px;

      img {
        width: 100%;
      }
      .walkscore_score {
        text-align: center;
        font-size: 35px;
        margin: 8px;

        .walkscore_score-link {
          text-decoration: none;
        }
      }
      .walkscore_description {
        text-align: center;
        padding: 10px 10px;
      }
      .bike-logo {
        text-align: center;
      }
    }
  }
  .searchStops {
    width: 100%;
    flex-wrap: wrap;
    // height: 50vh;
    margin-top: 2vh;
    // .score > div {
    //   height: 50%;
    //   padding: 0px 30px;
    // }
  }
  .stop {
    width: 50%;

    p {
      font-weight: 900;
      // font-size: 2.5vh;
    }

    .name {
      width: 100%;
    }

    ul li {
      margin: 10px 0px;
      list-style-type: none;
      // font-size: 2.5vh;
    }

    .distance {
      list-style-type: circle;
    }
  }
}

@media (max-width: 1024px) {
  .logos {
    .topLogo {
      width: 110px;
      height: 110px;
      padding: 5px 0px 5px 0px;
    }
  }
  .bottomDetails {
    .rentalFacts {
      .features {
        .col-container-features {
          width: 170%;
        }
      }
    }
    .unitDetails {
      .header {
        line-height: 1.3;
      }
    }
  }
  .bottomDetails .contactInfoContainer .message {
    padding: 3vh 1vh;
  }
}

@media (max-width: 768px) {
  .logos {
    .topLogo {
      // width: 30%;
      padding-top: 5px;
      padding-bottom: 5px;
      margin: 0px;
    }
  }

  .col-container-navigation {
    width: 70%;
  }

  .bottomDetails .contactInfoContainer .message {
    height: 200px;
    padding: 4vh 1vh;
  }
}

@media (max-width: 900px) {
  .logos {
    .topLogo {
      text-align: none;
      width: 75px;
      display: flex;
      height: 70px;
      height: 75px;
      padding: 5px 0px 5px 0px;
    }
  }

  .col-container-navigation {
    width: 100%;
  }

  .bottomDetails {
    display: block;
    position: absolute;

    .unitDetails {
      width: 100%;
      padding: 0 20px;

      p, li {
        font-size: 14px;
      }

      h1, h2, h3, h4 {
        font-size: 22px !important;
      }

      h4 {
        margin-top: 5px !important;
      }

      .header {
        line-height: 1.3;
      }

      .rentalFacts {
        padding-top: 1px;

        .features {
          ul {
            margin-left: 5px;
          }
          li {
            margin-bottom: 5px;
          }
          .col-container-features {
            font-size: 10px;
            width: $width + 1%;
            display: block;
            .col-table-cell-features {
              display: block;
              font-size: 18px;
              width: 100%;

              .detailsHeader {
                margin-top: 15px;
              }
            }
          }
        }
      }

      .unitWalkScore {
        .walkscore-api-container {
          margin-top: 35px;
          display: block;
        }

        .score {
          width: 50%;
          margin: 18px 0px;
        }

        .searchStops {
          .stop {
            width: 100%;
          }
        }

        .walkscore_logo {
          padding: 0px;
        }
      }
    }
    .contactInfoContainer {
      position: sticky;
      bottom: 0;
      width: $width;
      .contactInfo {
        margin: 35px 0px 0px 0px;
        margin-top: 0;
        .rent {
          padding: 1rem 1.5rem;
          h3 {
            margin: 0;
          }
        }
        .row-one {
          gap: 0;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .col-container-navigation {
    width: 100%;
  }
  .logos {
    .topLogo {
      width: 75%;
      padding: 5px 0px 5px 0px;
    }
  }
}

@media (max-width: 320px) {
  .col-container-navigation {
    .reset {
      display: none;
    }
  }

  .logos {
    .topLogo {
      width: 75px;
      padding: 5px 0px 5px 0px;
    }
  }
  #buttonFeedback {
    display: none;
  }

  #signOutButton {
    margin-left: auto;
  }
}

@media (min-width: 650px) {
  .topDetails{
    .unitMap {
      display: block;
    }
  }
}