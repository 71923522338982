.SignIn-Card {
  height: 100vh;
  width: 100%;
  background-color: #00ADEF;
  background-size: cover;
  font-family: 'Open Sans, sans-serif';
  display: block;
}

.SignIn {
  top: 50px;
  text-align: center;
  position: absolute;
  width: 400px;
  height: auto;
  left: 31%;
  padding: 38px;
  background: #fbfbfb !important;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition-delay: 0.5s;
  box-shadow: 0 0 1px 1px lightgrey;
}

.SignIn h1 {
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.SignIn p {
  font-family: 'Open Sans';
}

@media (max-width: 1024px) {
  .SignIn {
    width: 400px;
    height: auto;
    top: 50px;
    /* left: calc(50% - 327px); */
  }
}

@media (max-width: 768px) {
  .SignIn {
    width: 48%;
    /* left: calc(50% - 249px); */
    left: 21%;
    top: 50px;
  }
}

@media (max-width: 900px) {
  .SignIn-Card {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .SignIn {
    width: 90%;
    position: static; 
  }
}
