@import '../Components/style-components.scss';

$path-blue: #1dafec;
$path-green: #2db151;
$path-grey: #55585c;
$border-line: 1px solid #999999;
$box-shadow-list: 0 10px 10px #888888;
$width: 100%;
$height: 100%;

.App {
  text-align: center;

  .color-bar {
    width: $width;
    height: 33px;
    background-color: $path-blue;
  }

  .filter-bar {
    display: none;
    width: $width;
    height: 45px;
    background-color: $path-green;
    text-align: left;
  }

  .dashboard {
    height: 100vh;

    .nav-bar {
      background: #ffffff;
      height: 50px;
      width: $width;
      border-top: $border-line;
      border-bottom: $border-line;
      box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.2),
        0 6px 32px 0 rgba(0, 0, 0, 0.19);
    }

    .col-container {
      display: table;
      width: $width;
      height: $height;
      border-top: none;
    }

    .col-content-map {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: $width - 30%;
      height: $height - 10%;
      border-right: $border-line;
    }

    .col-content-listing {
      display: table-cell;
      width: $width - 70%;
      height: $height;
      position: absolute;
      overflow-y: scroll;
      box-shadow: $box-shadow-list;
    }

    .viewDetails {
      height: $height;
    }

    .loadingBubbles {
      padding: 100px 100px 100px 100px;
      text-align: -webkit-center;
    }
  }
}
.filters-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.available-props-title {
  display: flex;
  justify-content: center;
  flex: 1 1 50%;
}

@media (max-width: 1024px) {
  .App .dashboard .col-content-map {
    width: $width - 45%;
  }

  .App .dashboard .col-content-listing {
    width: $width - 55%;
  }
}

@media (max-width: 900px) {
  .filters-row {
    display: flex;
    margin: 15px 0;
    align-items: center;
    flex-flow: column-reverse nowrap;
    gap: 10px;
  }
}

@media (max-width: 414px) {
  .App .dashboard .col-content-map {
    display: none;
  }

  .App .dashboard .col-content-listing {
    width: $width;
    height: $height;
  }

  .dashboard {
    margin-top: -20px;
  }
}

.welcome-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  padding: 40px;
  background-color: white;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  color: $path-grey;
  overflow: scroll;
  max-height: 100vh;

  h1 {
    color: $path-blue;
  }

  p, h1, h3 {
    margin-bottom: .2em;
  }
}

.MuiButton-root.get-started-btn {
  background: $path-green;
  color: white;
  &:hover {
    color: black;
  }
  display: flex;
  padding: 12px;
  align-self: center;
}

#dont-show-again {
  display: flex;
  margin-top: 24px;
  align-self: center;
  input {
    margin-right: 8px;
  }
}