html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-size: 15px;
  font-family: 'Open Sans', 'sans-serif';
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

html, body, #root {
  background-color: white;
}

h4,
h3,
h2,
h1 {
  font-family: 'Jost', sans-serif;
}

a:hover {
  color: rgb(137, 040, 144);
}

.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 80px;
}
/* s */

.Toastify__toast--success {
  background: #0DB14B;
}

@media (min-width: 900px) {
  body {
    font-size: 16px;
  }
  .brand {
    display: none;
  }
}

@media (max-width: 900px) {
  .brand {
    display: flex;
    flex-flow: row nowrap;
    background-color: #00ADEF;
    padding: 1rem 1.5rem;
    align-content: center;
  }
}