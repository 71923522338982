.content {
  width: 100%;
  height: calc(100vh - 170px);
  background-color: #fff;
  padding: 0 !important;
}

.mobile-only {
  display: block !important;
}

.toolbar {
  display: flex;
  gap: 2%;
  padding: 0.5rem 0;
  align-items: flex-start;
  justify-content: flex-start;
}

.nested-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  align-self: flex-start;
}

.nested-container button {
  margin-top: 10px;
}

.dashboard-row {
  display: flex;
  margin: 15px 0;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}


.dashboard-row a {
  border-radius: 9999px;
}

@media (min-width: 960px) {
  .dashboard-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0;
  }
}

.empty-state {
  display: flex;
  height: calc(100% - 200px);
  align-items: center;
  justify-content: center;
  margin-top: 200px
}

.plus-icon {
  margin-right: 10px;
}

.loading-state {
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 0 !important;
}

.remove-link-styling a {
  text-decoration: none;
  color: white;
}
.remove-link-styling a:hover {
  color: white;
}

.light-blue {
  background-color: rgba(2, 163, 218, 0.1);
}

@media (min-width: 900px) {
  .mobile-only {
    display: none !important;
  }

  .content {
    padding: 15px 0 0 24px !important;
  }
}

