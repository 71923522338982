fieldset {
  border: 0;
}

.matching-container {
  display: flex;
  height: calc(100vh - 110px);
  padding: 0 10px;
  margin-top: 10px;
  flex-direction: column;
  margin-bottom: 20px;
}

.card-root {
  height: auto;
  width: 100%;
  margin-top: 100px;
  padding: 30px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    '...... card-content ......'
    'card-footer card-footer card-footer';
}

.stepper {
  padding: 0 !important;
  align-self: center;
  width: 100%;
  margin: 15px 0;
}

.step-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.card-header {
  margin-top: 0;
}

.card-content {
  grid-area: card-content;
  display: flex !important;
  justify-content: center;
  align-self: center;
  margin-bottom: 15px;
}

.card-footer {
  grid-area: card-footer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 2%;
  margin-top: 5px;
}

.saved-search-card {
  width: 350px;
}

.result-list-container {
  height: 400px;
}

.result-list-pagination {
  padding-bottom: 20px !important;
}

.result-list-stats {
  margin-left: 20px;
}

.show {
  display: block;
}

.hide {
  display: none !important;
}

.results-wrapper {
  display: flex;
  flex-flow: row-reverse nowrap;
  height: 100%;
  width: 100%;
  margin: 15px 0;
}

.results {
  flex: 1 1 50%;
  overflow: scroll;
  height: calc(100vh - 250px);
  > p {
    margin-left: 15px;
  }
}

.results-map {
  align-self: flex-start;
  background: lightgrey;
  flex: 1 1 50%;
  height: 100%;
  width: 100%;
}

.criteria {
  width: 80%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  row-gap: 10px;
}

@media (min-width: 960px) {
  .results {
    height: 100%;
    overflow: auto;
  }
  .stepper {
    width: 60%;
  }
  .card-root {
    width: 550px;
  }
}

@media (max-width: 960px) {
  .MuiStep-horizontal {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .card-root {
    width: 70%;
  }
}

@media (max-width: 900px) {
  .card-root {
    width: 100%;
    box-shadow: none !important;
    height: calc(100vh - 200px);
    margin-top: 0;
  }
  .results {
    flex: 1 1 70%;
  }
  .results-map {
    height: auto;
  }
}

@media (max-width: 844px) {
  .results-wrapper {
    flex-flow: column-reverse nowrap;
  }
}
