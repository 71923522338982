.PasswordForget {
  text-align: center;
  position: absolute;
  width: auto;
  height: auto;
  top: calc(50% - 180px);
  left: 36%;
  padding: 38px;
  background: #fbfbfb !important;
  border: 1px solid #d9d9d9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  /* border-radius: 1rem; */
}
